import { z } from 'zod';

export const FeatureFlagValidator = z.object({
  dark_mode: z.boolean().optional(),
  enable_stripe: z.boolean().optional(),
});

export type FeatureFlagObject = z.infer<typeof FeatureFlagValidator>;

export type FeatureFlag = keyof FeatureFlagObject;

export interface IFeatureFlagService {
  getCurrentFeatureFlags(): FeatureFlagObject;
  setFeatureFlag(feature: FeatureFlag, newValue: boolean): FeatureFlagObject;
}
