import {
  classToPlain,
  ClassTransformOptions,
  instanceToInstance,
  plainToClass,
} from 'class-transformer';

export type ClassType<T> = new (...args: unknown[]) => T;
import { parsePhoneNumber } from 'awesome-phonenumber';
import { StringResources } from 'src/assets/StringResources';
import { Colors } from 'src/Components/Shared/Colors';
import { ToastType } from 'src/Components/Shared/Toast';
import { TCollapseState } from 'src/Components/Shared/Collapse';
import { JobType } from 'src/graphql/__generated__/globalTypes';
import { RouterLinks } from 'src/routing/routerLinks';

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return Boolean(value);
}

export function getStartOfDay(dd: Date): Date {
  const d = new Date(dd);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return d;
}

export function first<T>(items: T[]): T | undefined {
  if (isEmpty(items)) {
    return undefined;
  }
  return items[0];
}

export function getEndOfDay(dd: Date): Date {
  const d = new Date(dd);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  return d;
}

export function compareTimeOnly(d1: Date, d2: Date): 0 | 1 | -1 {
  d1.setMonth(1);
  d1.setDate(1);
  d1.setFullYear(2020);
  d2.setMonth(1);
  d2.setDate(1);
  d2.setFullYear(2020);
  if (d1 === d2) {
    return 0;
  }
  if (d1 < d2) {
    return -1;
  }
  return 1;
}

export function getDateMinusDays(dd: Date, days: number): Date {
  const d = new Date(dd);
  d.setDate(d.getDate() - days);
  return d;
}

export function isEmpty<T>(l: T[]): boolean {
  return l.length === 0;
}

export function displayPhoneNumber(n: string): string {
  return parsePhoneNumber(`+1${n}`).number?.national || n;
}

export function getJobTypeDisplayName(jobType: JobType): string {
  switch (jobType) {
    case JobType.MECHANIC:
      return StringResources.mechanic;
    case JobType.OIL:
      return StringResources.Oil;
    case JobType.UNDER_COAT:
      return StringResources.UnderCoat;
    case JobType.OTHER:
      return StringResources.Other;
    case JobType.UNKNOWN:
      return StringResources.Unknown;
    case JobType.TIRE:
      return StringResources.Tire;
    case JobType.PURCHASE:
      return StringResources.Purchase;
    default:
      isNever(jobType);
  }
}

export function displayJobType(jobType: JobType): string {
  return getJobEmoji(jobType) + getJobTypeDisplayName(jobType);
}

export function getJobEmoji(jobType: JobType): string {
  switch (jobType) {
    case JobType.MECHANIC:
      return '🧰';
    case JobType.OIL:
      return '🛢';
    case JobType.UNDER_COAT:
      return '‿';
    case JobType.OTHER:
    case JobType.UNKNOWN:
      return '❓';
    case JobType.TIRE:
      return '🚙';
    case JobType.PURCHASE:
      return '💲';
    default:
      isNever(jobType);
  }
  return '';
}

export function displayPrice(
  priceInCents: number | undefined = 0,
  hideCents: boolean = false
): string {
  if (priceInCents !== 0) {
    priceInCents /= 100;
  }
  // return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3
  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', // TODO: Modify
      // maximumSignificantDigits: 2,
      maximumFractionDigits: hideCents ? 0 : undefined,
    }).format(priceInCents) + ''
  );
  // return '$' + Math.round((priceInCents + Number.EPSILON) * 100) / 100;
}
export function floatToString(num: number): string {
  return String(num.toFixed(2));
}

export function getFullCarName(car: {
  colour?: string | null;
  year?: number | null;
  make?: string | null;
  model?: string | null;
}): string {
  return [car.colour, car.year, car.make, car.model].filter(notEmpty).join(' ');
}

export function dateToString(
  date: Date,
  options: {
    removeTime?: boolean;
    includeYear?: boolean;
    removeDate?: boolean;
    removeDay?: boolean;
  } = {
    removeTime: false,
    includeYear: false,
    removeDate: false,
    removeDay: false,
  }
): string {
  const opt: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
  };
  if (options.removeTime) {
    opt.hour = undefined;
    opt.minute = undefined;
  }
  if (options.removeDate) {
    opt.weekday = undefined;
    opt.month = undefined;
    opt.day = undefined;
    opt.year = undefined;
  }
  if (options.removeDay) {
    opt.weekday = undefined;
  }
  if (options.includeYear) {
    opt.year = 'numeric';
  }
  return date.toLocaleDateString('en-US', opt);
}

export function dateToTimeString(date: Date) {
  return date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    // timeZoneName: 'short',
  });
}

export function timeToString(date: Date): string {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function dateToUtcString(date: Date): string {
  return date.toUTCString();
}

export function copy<T>(obj: T): T {
  return instanceToInstance(obj);
}

export function isToday(date: Date): boolean {
  return new Date().toLocaleDateString() === date.toLocaleDateString();
}

export function isNever(_: never): never {
  return _;
}

export function isNullOrUndefinedOrEmptyString<T>(item: T | string): boolean {
  return item === null || item === undefined || item === '';
}

export function range(start: number, end: number): number[] {
  return [...Array.from(Array.from(Array(1 + end - start)).keys())].map(
    v => start + v
  );
}

export function onEnterPress(
  doThis: (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
    >
  ) => void
) {
  return (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
    >
  ): void => {
    if (e.key === 'Enter') {
      doThis(e);
    }
  };
}

const flat = <T>(arr: Array<T | T[]>, res: T[]): void => {
  let cur;
  const len = arr.length;
  for (let i = 0; i < len; i++) {
    cur = arr[i];
    if (Array.isArray(cur)) {
      flat(cur, res);
    } else {
      res.push(cur);
    }
  }
};

export function flatten<T>(arr: Array<T | T[]>): T[] {
  const res: T[] = [];
  flat(arr, res);
  return res;
}

export function classToJson<T, R>(cls: T): R {
  return classToPlain(cls) as R;
}

export function classToJsonString<T>(cls: T): string {
  const plain = classToJson(cls);
  return JSON.stringify(plain);
}

export function jsonToClass<T, V>(
  cls: ClassType<T>,
  plain: V,
  options?: ClassTransformOptions
): T | undefined {
  const c = plainToClass(cls, plain, options);
  // const validation = validateSync(c);
  // if (validation.length > 0) {
  //   console.log(validation);
  //   // return undefined;
  // }
  return c;
}

export function stringToClass<T>(
  cls: ClassType<T>,
  plain: string
): T | undefined {
  return jsonToClass(cls, JSON.parse(plain));
}

export function noop(): void {
  return;
}

// Return the uniques given the id of each item.
// Only the LAST occurence of every item will remain.
export function uniques<T>(arr: T[], toId: (t: T) => string): T[] {
  const m = new Map<string, T>();
  arr.forEach(i => m.set(toId(i), i));
  return Array.from(m.values());
}

export function non_uniques<T>(arr: T[], toId: (t: T) => string): T[] {
  const m = new Map<string, T>();
  const counts = new Map<string, number>();
  arr.forEach(i => {
    const id = toId(i);
    m.set(id, i);
    const oldCount = counts.get(id) || 0;
    counts.set(id, oldCount + 1);
  });

  return arr.filter(item => (counts.get(toId(item)) || 0) > 1);
}

export type StringifiedInternal<ObjectType> = {
  [KeyType in keyof ObjectType]: string;
};

export function dropLast<T>(list: T[]): T[] {
  const l = [...list];
  l.pop();
  return l;
}

export function round(num: number, toClosest: number): number {
  return Math.round(num / toClosest) * toClosest;
}

export function sum(arr: number[]): number {
  return arr.reduce((prev, curr) => prev + curr, 0);
}

export function getCollapsState(type: ToastType): TCollapseState {
  switch (type) {
    case ToastType.error:
      return 'error';
    case ToastType.info:
    case ToastType.success:
      return 'complete';
    case ToastType.loading:
      return 'loading';
    case ToastType.warning:
      return 'warning';
  }
}
export function getColorForIndex(index: number, offset = 0): string {
  const colors = [
    Colors.dashboardColor1,
    Colors.dashboardColor2,
    Colors.dashboardColor3,
    Colors.dashboardColor4,
    Colors.dashboardColor5,
    Colors.dashboardColor6,
    Colors.dashboardColor7,
    Colors.dashboardColor8,
  ];
  return colors[(index + offset) % colors.length];
}

export function getFirstErrorMessage(
  consraints:
    | {
        [type: string]: string;
      }
    | undefined
): string | undefined {
  if (!consraints) {
    return undefined;
  }
  const firstKey = first(Object.keys(consraints));
  if (!firstKey) {
    return undefined;
  }
  return consraints[firstKey];
}

export function getLast<T>(list: T[]): T | undefined {
  return [...list].pop();
}

export function getPath(link: RouterLinks, extraRoutes: string[] = []): string {
  return link + (extraRoutes ? '/' + extraRoutes.join('/') : '');
}

export function removeNull<T>(item: T | null | undefined) {
  return item !== null && item !== undefined ? item : undefined;
}

export function removeUndefined<T>(item: T | null | undefined) {
  return item !== null && item !== undefined ? item : null;
}

export type WithoutTypename<T> = T extends object
  ? Omit<{ [key in keyof T]: WithoutTypename<T[key]> }, '__typename'>
  : T;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type Nullable<T> = { [P in keyof T]?: T[P] | null };

export function updateItems<T extends { id: string }>(
  items: T[],
  newItem: T | undefined
): T[] {
  return items.map(oldItem => {
    if (newItem && newItem.id === oldItem.id) {
      return newItem;
    } else {
      return oldItem;
    }
  });
}

export function modulo(num: number, n2: number): number {
  return ((num % n2) + n2) % n2;
}
