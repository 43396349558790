import { CookieSetOptions } from 'universal-cookie';

export enum LocalStorageItem {
  USER = 'USER',
}

export enum CookieType {
  SQUARE_SESSION_SECRET = 'SQUARE_SESSION_SECRET',
}

export interface ILocalStorageService {
  set(item: LocalStorageItem, data: string): void;
  get(item: LocalStorageItem): string | undefined;
  remove(item: LocalStorageItem): void;

  saveCookie(
    cookieType: CookieType,
    item: string,
    options?: Omit<CookieSetOptions, 'sameSite'>
  ): void;
  getCookie(cookieType: CookieType): string | undefined;
  removeCookie(cookieType: CookieType): void;
}
