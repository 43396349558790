import { injectable } from 'inversify';
import { RouterLinks } from 'src/routing/routerLinks';
import {
  AccountFeatures,
  IAccountViewSettings,
  ISidebarService,
  ISquareSetupSettings,
  SidebarSetting,
  TSidebarItem,
  TSidebarRouterLinks,
} from './ISidebarService';

@injectable()
export class PointOfSaleSidebarService implements ISidebarService {
  public getItems(): TSidebarItem[] {
    const res: TSidebarRouterLinks[] = [
      RouterLinks.Notifcations,
      RouterLinks.Appointments,
      RouterLinks.workOrders,
    ];
    return res.map(link => ({ isDisabled: false, link }));
  }

  public getAccountViewSettings(): IAccountViewSettings {
    return {
      canSaveAccount: false,
      company: {
        isShown: true,
        isDisabled: true,
      },
      twilioPhoneNumber: {
        isShown: false,
        isDisabled: true,
      },
    };
  }

  public getAvailableSettings(): SidebarSetting[][] {
    return [['theme', 'logout']];
  }

  public getSquareSetupSettings(): ISquareSetupSettings {
    return {
      canConnectToSquare: false,
      canConnectNewTerminal: true,
      canSyncPayments: false,
      canSetLocation: false,
      canSetCurrency: false,
      canSetTaxPercentage: false,
    };
  }

  public getEnabledFeatures(): AccountFeatures {
    return {
      createAndSearchResources: true,
      shiftInSidebar: true,
      manageShiftPage: true,
    };
  }
}
