import 'reflect-metadata';

import { Container, interfaces } from 'inversify';
import { IEnvironmentService } from 'src/services/EnvironmentService/IEnvironmentService';
import { LocalStorageService } from 'src/services/LocalStorageService/LocalStorageService';

import { AuthService } from '../services/AuthService/AuthService';
import { IAuthService } from '../services/AuthService/IAuthService';
import { BrowserHistoryService } from '../services/BrowserHistory/BrowserHistoryService';
import { IBrowserHistory } from '../services/BrowserHistory/IBrowserHistoryService';
import { EnvironmentService } from '../services/EnvironmentService/EnvironmentService';
import { HttpService } from '../services/HttpService/HttpService';
import { IHttpService } from '../services/HttpService/IHttpService';
import { ILocalStorageService } from '../services/LocalStorageService/ILocalStorageService';
import { IStringValidationService } from '../services/StringValidationService/IStringValidationService';
import { StringValidationService } from '../services/StringValidationService/StringValidationService';
import { DI_TYPES } from './diTypes';
import { ISidebarService } from '../services/SidebarService/ISidebarService';
import {
  AccountType,
  AuthTokenType,
} from 'src/graphql/__generated__/globalTypes';
import { OwnerSidebarService } from '../services/SidebarService/OwnerSidebarService';
import { PointOfSaleSidebarService } from '../services/SidebarService/PointOfSaleSidebarService';
import { WorkshopSidebarService } from 'src/services/SidebarService/WorkshopSidebarService';
import { ApolloService } from 'src/services/ApolloService/ApolloService';
import { IApolloService } from 'src/services/ApolloService/IApolloService';
import { IAnalyticsService } from 'src/services/AnalyticsService/IAnalyticsService';
import { AnalyticsService } from 'src/services/AnalyticsService/AnalyticsService';
import { IFeatureFlagService } from 'src/services/FeatureFlagService/FeatureFlagServiceTypes';
import { FeatureFlagService } from 'src/services/FeatureFlagService/FeatureFlagService';
import { IStripeService } from 'src/services/StripeService/StripeServiceTypes';
import { StripeService } from 'src/services/StripeService/StripeService';
import { MechanicSidebarService } from 'src/services/SidebarService/MechanicSidebarService';

export function bindServices(c: Container): void {
  c.bind<ILocalStorageService>(DI_TYPES.LocalStorageService)
    .to(LocalStorageService)
    .inSingletonScope();
  c.bind<IHttpService>(DI_TYPES.HttpService).to(HttpService).inSingletonScope();
  c.bind<IAuthService>(DI_TYPES.AuthService).to(AuthService).inSingletonScope();
  c.bind<IEnvironmentService>(DI_TYPES.EnvironmentService)
    .to(EnvironmentService)
    .inSingletonScope();
  c.bind<IStripeService>(DI_TYPES.StripeService)
    .to(StripeService)
    .inSingletonScope();

  c.bind<IStringValidationService>(DI_TYPES.StringValidationService)
    .to(StringValidationService)
    .inSingletonScope();
  c.bind<IBrowserHistory>(DI_TYPES.BrowserHistoryService)
    .to(BrowserHistoryService)
    .inSingletonScope();
  c.bind<ISidebarService>(DI_TYPES.OwnerSidebarService)
    .to(OwnerSidebarService)
    .inSingletonScope();
  c.bind<ISidebarService>(DI_TYPES.PointOfSaleSidebarService)
    .to(PointOfSaleSidebarService)
    .inSingletonScope();
  c.bind<ISidebarService>(DI_TYPES.WorkshopSidebarService)
    .to(WorkshopSidebarService)
    .inSingletonScope();
  c.bind<IAnalyticsService>(DI_TYPES.AnalyticsService)
    .to(AnalyticsService)
    .inSingletonScope();
  c.bind<ISidebarService>(DI_TYPES.MechanicSidebarService)
    .to(MechanicSidebarService)
    .inSingletonScope();

  c.bind<interfaces.Factory<ISidebarService>>(
    DI_TYPES.SidebarFactory
  ).toFactory<ISidebarService>((context: interfaces.Context) => {
    return () => {
      const authService = c.get<IAuthService>(DI_TYPES.AuthService);
      switch (authService.getCurrentUser()?.authTokenType) {
        case AuthTokenType.ADMIN:
        case undefined:
        case null:
          return context.container.get<ISidebarService>(
            DI_TYPES.OwnerSidebarService
          );
        case AuthTokenType.FRONT_DESK:
          return context.container.get<ISidebarService>(
            DI_TYPES.PointOfSaleSidebarService
          );
        case AuthTokenType.SHOP:
          return context.container.get<ISidebarService>(
            DI_TYPES.WorkshopSidebarService
          );
        case AuthTokenType.MECHANIC:
          return context.container.get<ISidebarService>(
            DI_TYPES.MechanicSidebarService
          );
        default:
          throw new Error('Not logged in.');
      }
    };
  });

  c.bind<IApolloService>(DI_TYPES.ApolloService)
    .to(ApolloService)
    .inSingletonScope();
  c.bind<IFeatureFlagService>(DI_TYPES.FeatureFlagService).to(
    FeatureFlagService
  );
}
