import { cn } from 'src/shadcn/lib/utils';
import * as React from 'react';

export interface ILogoProps {
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  onClick?: () => void;
}

export const LogoWithIcon: React.FunctionComponent<ILogoProps> = props => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 801 204.14'
      className={cn('fill-blue-700', props.className)}
      onClick={props.onClick}
    >
      <title>Sparkify</title>
      <path
        className={cn('hover:text-accent-foreground', props.iconClassName)}
        d='M126.88,82.16l-1.36-2,24.4-29.74A30.83,30.83,0,0,0,126.08,0H53.59A53.09,53.09,0,0,0,9.74,83l20.34,29.82,1.36,2L7,144.6A30.86,30.86,0,0,0,30.88,195H103.4a53.09,53.09,0,0,0,43.85-83Zm-2.25,71a23.7,23.7,0,0,1-21.23,12.8h-1.88L33.76,66.65A24,24,0,0,1,53.59,29.08h1.85l67.79,99.27A23.7,23.7,0,0,1,124.63,153.13Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M281.51,130a26.31,26.31,0,0,1-11,9.83q-7.27,3.66-17.62,3.66-15.58,0-25.67-7.59t-11-21.22h23.84a12.59,12.59,0,0,0,3.73,8.25,11.48,11.48,0,0,0,8.19,3,9.51,9.51,0,0,0,6.81-2.35,8.31,8.31,0,0,0,2.49-6.29,8.1,8.1,0,0,0-2.3-5.89,17.82,17.82,0,0,0-5.69-3.87q-3.4-1.5-9.43-3.6a115.52,115.52,0,0,1-14.34-5.83A26.25,26.25,0,0,1,220,89.74q-4-5.56-4-14.47A24.42,24.42,0,0,1,220.15,61a26.74,26.74,0,0,1,11.59-9.23,42.26,42.26,0,0,1,17-3.21q15.46,0,24.56,7.33t10.28,20.17H259.31A12.44,12.44,0,0,0,256,68.79a9.82,9.82,0,0,0-7.27-2.69,9.35,9.35,0,0,0-6.35,2.1,7.67,7.67,0,0,0-2.42,6.15,7.84,7.84,0,0,0,2.16,5.57,16.42,16.42,0,0,0,5.43,3.73q3.28,1.44,9.43,3.67a102.88,102.88,0,0,1,14.54,6,27.67,27.67,0,0,1,9.69,8.57q4.06,5.64,4.06,14.67A25.5,25.5,0,0,1,281.51,130Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M355.72,73.11a31,31,0,0,1,11.27,13q4.13,8.45,4.12,20A45.14,45.14,0,0,1,367,126a30.87,30.87,0,0,1-11.27,13,29.53,29.53,0,0,1-16.17,4.52,26.41,26.41,0,0,1-14.41-3.8,22.08,22.08,0,0,1-8.78-10.34v48.06H294V69.51h22.39V82.73a22.08,22.08,0,0,1,8.78-10.34,26.49,26.49,0,0,1,14.41-3.8A29.62,29.62,0,0,1,355.72,73.11Zm-35,19.9q-4.51,4.78-4.51,13t4.51,13a16.43,16.43,0,0,0,23.18.13q4.4-4.71,4.39-13.09T343.93,93a15.1,15.1,0,0,0-11.59-4.72A15.27,15.27,0,0,0,320.75,93Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M425.42,72.39a22.82,22.82,0,0,1,8.91,10.34V69.51h22.26v73.08H434.33V129.36a22.82,22.82,0,0,1-8.91,10.34,26.37,26.37,0,0,1-14.4,3.8A29.54,29.54,0,0,1,394.84,139a30.85,30.85,0,0,1-11.26-13,45.14,45.14,0,0,1-4.13-19.9q0-11.53,4.13-20a31,31,0,0,1,11.26-13A29.63,29.63,0,0,1,411,68.59,26.46,26.46,0,0,1,425.42,72.39ZM406.63,93q-4.39,4.71-4.39,13.1t4.39,13.09a16.43,16.43,0,0,0,23.18-.13q4.51-4.85,4.52-13t-4.52-13a15.27,15.27,0,0,0-11.59-4.78A15.08,15.08,0,0,0,406.63,93Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M498.91,72.65a27.53,27.53,0,0,1,14.34-3.93v24h-6.42q-8.91,0-13.82,3.8t-4.91,12.83v33.27H465.71V69.51H488.1V83.26A31.7,31.7,0,0,1,498.91,72.65Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M566.24,142.59l-21.87-31v31H522V45.67h22.4V98.84l21.87-29.33h26.33L563.1,106.05l30.12,36.54Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M602.29,40.5Q606,37.16,612,37.16a13.84,13.84,0,0,1,9.69,3.34,10.93,10.93,0,0,1,3.67,8.44,10.82,10.82,0,0,1-3.67,8.32A13.84,13.84,0,0,1,612,60.6q-6,0-9.69-3.34a10.78,10.78,0,0,1-3.67-8.32A10.9,10.9,0,0,1,602.29,40.5Zm20.82,29v73.08h-22.4V69.51Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M673.75,59.55q-5.5,0-8,2.36c-1.65,1.57-2.57,4.11-2.75,7.6h13.23V88.37H663v54.22H640.61V88.37h-8.77V69.51h8.77v-.79q0-13.62,7.92-21t23-7.4c2.09,0,3.67,0,4.71.13V59.68Z'
      />
      <path
        className={cn('fill-foreground', props.textClassName)}
        d='M707.67,69.51l17.55,44.79,16.37-44.79h24.75L720.76,177.42H696.14l17.16-37.58L682.65,69.51Z'
      />
    </svg>
  );
};
