import * as React from 'react';

export interface IErrorPageContainerProps extends React.PropsWithChildren {}

export const ErrorPageContainer: React.FunctionComponent<
  IErrorPageContainerProps
> = props => {
  return (
    <div className='flex h-[100vh] items-center justify-center'>
      <div className='flex flex-col'>{props.children}</div>
    </div>
  );
};
