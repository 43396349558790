import Link from 'next/link';
import * as React from 'react';
import { RouterLinks } from 'src/routing/routerLinks';
import { LogoWithIcon } from '../Landing/LogoWithIcon';
import { cn } from 'src/shadcn/lib/utils';

export interface IErrorPageLogoProps {
  href?: string;
}

export const ErrorPageLogo: React.FunctionComponent<
  IErrorPageLogoProps
> = props => {
  return (
    <Link href={props.href || `/${RouterLinks.Dashboard}`}>
      <LogoWithIcon
        className={cn(
          'mx-auto mb-7 flex h-[40px] cursor-pointer hover:opacity-70'
        )}
      />
    </Link>
  );
};
