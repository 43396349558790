import { injectable } from 'inversify';
import {
  AccountFeatures,
  IAccountViewSettings,
  ISidebarService,
  ISquareSetupSettings,
  SidebarSetting,
  TSidebarItem,
} from './ISidebarService';

@injectable()
export class WorkshopSidebarService implements ISidebarService {
  public getItems(): TSidebarItem[] {
    return [];
  }
  public getAccountViewSettings(): IAccountViewSettings {
    return {
      canSaveAccount: false,
      company: {
        isShown: false,
        isDisabled: false,
      },
      twilioPhoneNumber: {
        isShown: false,
        isDisabled: false,
      },
    };
  }

  public getAvailableSettings(): SidebarSetting[][] {
    return [];
  }

  public getSquareSetupSettings(): ISquareSetupSettings {
    return {
      canConnectToSquare: false,
      canConnectNewTerminal: false,
      canSyncPayments: false,
      canSetLocation: false,
      canSetCurrency: false,
      canSetTaxPercentage: false,
    };
  }

  public getEnabledFeatures(): AccountFeatures {
    return {
      createAndSearchResources: true,
      shiftInSidebar: false,
      manageShiftPage: false,
    };
  }
}
