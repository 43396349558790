import { ApolloLink } from '@apollo/client';
import { diContainer } from 'src/dependencyInjection/diContainer';
import { DI_TYPES } from 'src/dependencyInjection/diTypes';
import { AnalyticsEvent } from 'src/services/AnalyticsService/AnalyticsServiceTypes';
import { IAnalyticsService } from 'src/services/AnalyticsService/IAnalyticsService';

export const SegmentApolloLink = new ApolloLink((operation, forward) => {
  const analytics = diContainer.get<IAnalyticsService>(
    DI_TYPES.AnalyticsService
  );
  return forward(operation).map(data => {
    const { operationName } = operation;
    if (operationName) {
      analytics.track(AnalyticsEvent.CLIENT_GQL_CALL, { operationName });
    }
    return data;
  });
});
