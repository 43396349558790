import { injectable } from 'inversify';
import { IEnvironment } from 'src/interfaces/IEnvironment';

import { EnvType } from 'src/interfaces/IEnvironment';
import { IEnvironmentService } from './IEnvironmentService';

@injectable()
export class EnvironmentService implements IEnvironmentService {
  public get(): IEnvironment {
    const envType = this.getEnv();
    let env: IEnvironment;
    switch (envType) {
      case EnvType.DEV:
        env = this.getDevEnv();
        break;
      case EnvType.PREVIEW:
        env = this.getPreviewEnv();
        break;
      case EnvType.PROD:
        env = this.getProdEnv();
        break;
    }
    return {
      ...env,
    };
  }

  private getEnv(): EnvType {
    const env = process.env.NEXT_PUBLIC_SPARK_ENV;
    switch (env) {
      case EnvType.DEV:
        return EnvType.DEV;
      case EnvType.PREVIEW:
        return EnvType.PREVIEW;
      case EnvType.PROD:
        return EnvType.PROD;
    }
    throw Error('NEXT_PUBLIC_SPARK_ENV environment var is not recognized');
  }

  private getDevEnv() {
    return {
      productionEndpoint: `http://localhost:3000/`,
      endpoint: {
        current: `http://localhost:5001/`,
        socket: `ws://localhost:5001/`,
      },
      envType: EnvType.DEV,
      square: {
        endpoint: 'https://connect.squareupsandbox.com/',
        applicationId: 'sandbox-sq0idb-2vGtR0NAVY63ZlT1cB2i4Q',
        inputComponentScriptEndpoint:
          'https://sandbox.web.squarecdn.com/v1/square.js',
      },
      stripe: {
        subscriptions: {
          monthly: {
            id: 'price_1KDQNIGo4fpiSPaz98RuTd6y',
            pricePerMonth: 12900,
          },
          annually: {
            id: 'price_1KDQMdGo4fpiSPazJ9fBfmK5',
            pricePerMonth: 9900,
          },
        },
        priceTable: {
          id: 'prctbl_1NQ30dGo4fpiSPazT6KjjIOA',
          publishableKey:
            'pk_test_51KBAYYGo4fpiSPazdzTIXATaBokIdMAp7qXuH2vllDIr3GP8SMRSW5kB4XvkpDtlqpmCSb0YLP81moJSFlVM0PBS00WyJnn2hj',
        },
        api_key:
          'pk_test_51OBsnlGb4BZtary1bIsXzw1peG13qE9ErGbuhdsGsIzHnvZvz9IrTYmZkngsTKYWrVcU051OJVZxvVPBdAuCkWK0003at5Gri6',
      },
      segment: {
        apiKey: 'rCfJWayIcOeSGdCO4srSm92YSmV2mGD3',
      },
      drift: {
        appId: 'n3z68pg8wer9',
      },
      cloudflare: {
        // For other keys:
        // https://developers.cloudflare.com/turnstile/troubleshooting/testing/
        siteKey: '1x00000000000000000000AA',
      },
    };
  }

  private getPreviewEnv() {
    const backendUrl = process.env.NEXT_PUBLIC_RAILWAY_STATIC_URL;
    return {
      productionEndpoint: 'https://shopsparkify.com',
      endpoint: {
        current: `https://${backendUrl}/`,
        socket: `wss://${backendUrl}/`,
      },
      envType: EnvType.PREVIEW,
      square: {
        applicationId: 'sandbox-sq0idb-2vGtR0NAVY63ZlT1cB2i4Q',
        inputComponentScriptEndpoint:
          'https://sandbox.web.squarecdn.com/v1/square.js',
      },
      stripe: {
        subscriptions: {
          monthly: {
            id: 'price_1KDQNIGo4fpiSPaz98RuTd6y',
            pricePerMonth: 12900,
          },
          annually: {
            id: 'price_1KDQMdGo4fpiSPazJ9fBfmK5',
            pricePerMonth: 9900,
          },
        },

        priceTable: {
          id: 'prctbl_1LvvgWGo4fpiSPazk94Cux7E',
          publishableKey:
            'pk_live_51KBAYYGo4fpiSPazDgRyL74edg7qGxBhEEI8jotTIy7Xyzkx4bJNLzIyEF48nxjLxpQXvl6ra1PaGMu3UeJkzpf500nHRSslJj',
        },
        api_key:
          'pk_live_51KBAYYGo4fpiSPazDgRyL74edg7qGxBhEEI8jotTIy7Xyzkx4bJNLzIyEF48nxjLxpQXvl6ra1PaGMu3UeJkzpf500nHRSslJj',
      },
      segment: {
        apiKey: 'rCfJWayIcOeSGdCO4srSm92YSmV2mGD3',
      },
      drift: {
        appId: 'n3z68pg8wer9',
      },
      cloudflare: {
        siteKey: '1x00000000000000000000AA',
      },
    };
  }

  private getProdEnv() {
    const backendUrl = 'mechanic-backend-production.up.railway.app';
    return {
      productionEndpoint: 'https://shopsparkify.com',
      endpoint: {
        current: `https://${backendUrl}/`,
        socket: `wss://${backendUrl}/`,
      },
      envType: EnvType.PROD,
      square: {
        applicationId: 'sq0idp-mBJhPB2YyQa0HWjj2S7k-Q',
        inputComponentScriptEndpoint: 'https://web.squarecdn.com/v1/square.js',
      },
      stripe: {
        subscriptions: {
          monthly: {
            id: 'price_1KDQEWGo4fpiSPazmBa45FQZ',
            pricePerMonth: 12900,
          },
          annually: {
            id: 'price_1KDQDxGo4fpiSPaznkVczA9Y',
            pricePerMonth: 9900,
          },
        },
        priceTable: {
          id: 'prctbl_1LvvgWGo4fpiSPazk94Cux7E',
          publishableKey:
            'pk_live_51KBAYYGo4fpiSPazDgRyL74edg7qGxBhEEI8jotTIy7Xyzkx4bJNLzIyEF48nxjLxpQXvl6ra1PaGMu3UeJkzpf500nHRSslJj',
        },
        api_key:
          'pk_live_51KBAYYGo4fpiSPazDgRyL74edg7qGxBhEEI8jotTIy7Xyzkx4bJNLzIyEF48nxjLxpQXvl6ra1PaGMu3UeJkzpf500nHRSslJj',
      },
      segment: {
        apiKey: 'rCfJWayIcOeSGdCO4srSm92YSmV2mGD3',
      },
      drift: {
        appId: 'n3z68pg8wer9',
      },
      cloudflare: {
        siteKey: '0x4AAAAAAAY1W2uFRlpi-TrE',
      },
    };
  }
}
