export const DI_TYPES = {
  MechanicSidebarService: Symbol('MechanicSidebarService'),
  LocalStorageService: Symbol('LocalStorageService'),
  HttpService: Symbol('HttpService'),
  AuthService: Symbol('AuthService'),
  EnvironmentService: Symbol('EnvironmentService'),
  StripeService: Symbol('StripeService'),
  StringValidationService: Symbol('StringValidationService'),
  BrowserHistoryService: Symbol('BrowserHistoryService'),
  SidebarFactory: Symbol('SidebarFactory'),
  OwnerSidebarService: Symbol('OwnerSidebarService'),
  PointOfSaleSidebarService: Symbol('PointOfSaleSidebarService'),
  WorkshopSidebarService: Symbol('WorkshopSidebarService'),
  ApolloService: Symbol('ApolloService'),
  AnalyticsService: Symbol('AnalyticsService'),
  FeatureFlagService: Symbol('FeatureFlagService'),
};
