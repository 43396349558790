export enum RouterLinks {
  // Dashboard
  Today = 'dashboard/today',
  Dashboard = 'dashboard',
  Payments = 'dashboard/payments',
  expenses = 'dashboard/expenses',
  workOrders = 'dashboard/work-orders',
  Customers = 'dashboard/customers',
  employeeAccounts = 'dashboard/employees/accounts',
  DayReport = 'dashboard/day-reports',
  bankDeposists = 'dashboard/bank-deposits',
  Campaigns = 'dashboard/campaigns',
  uploadExpenseImage = 'dashboard/expenses/upload-image',
  Appointments = 'dashboard/appointments',
  Notifcations = 'dashboard/notifications',
  GPTDashboard = 'dashboard/ai_assistant',
  // Settings
  Settings = 'settings/account',
  Account = 'settings/account',
  Billing = 'settings/billing',
  Employees = 'settings/employees',
  Services = 'settings/services',
  Locations = 'settings/locations',
  StripeSettings = 'settings/stripe',
  Shifts = 'settings/shifts/report',
  ShiftsReport = 'settings/shifts/report',
  ShiftsCurrentShifts = 'settings/shifts/current_shifts',

  // Onboarding Checklist
  OCAccount = 'dashboard/oc/account',
  OCContactInfo = 'dashboard/oc/contact-info',
  OCTerminalSetup = 'dashboard/oc/terminal-setup',
  OCSquareConnection = 'dashboard/oc/square-connection',
  OCSquareOptions = 'dashboard/oc/square-options',
  OCInvoiceOptions = 'dashboard/oc/invoice-options',
  OCCongratulations = 'dashboard/oc/congratulations',

  // open
  Showcase = 'showcase',
  Game = 'game',

  // customer
  customerInvoice = 'cx/invoice',
  customerQuote = 'cx/quote',
  customerWorkOrder = 'cx',

  // Auth
  SignUp = 'dashboard/auth/signup',
  Login = 'dashboard/auth/login',
  forgotPassword = 'dashboard/auth/forgot-password',
  resetPassword = 'dashboard/auth/reset-password',

  // Stripe
  stripeSubscriptionSuccess = 'dashboard/stripe/subscription-success',
  stripeSubscriptionCancel = 'dashboard/stripe/subscription-cancel',

  // Square
  SquareCode = 'square/code',
  Blog = 'blog',
  // TODO: remove
  Home = 'dashboard',
  Campaign = 'campaign',

  // misc
  Debug = 'dashboard/debug',
  RecommendationChatBot = 'chat/recommendation',
}
