export enum InputStringType {
  USERNAME,
  PASSWORD,
  NAME,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE,
  NON_EMPTY,
}

export interface IValidationResult {
  errorMessage?: string;
}

export interface IStringValidationService {
  validate(type: InputStringType, value?: string): IValidationResult;
}
