import * as React from 'react';
import {
  ErrorBoundaryProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';

export type IErrorBoundaryProps = ErrorBoundaryProps;

export const ErrorBoundary: React.FunctionComponent<
  IErrorBoundaryProps
> = props => {
  return (
    <ReactErrorBoundary
      {...props}
      onError={(error, info) => {
        // HANDLE logging
        if (props.onError) {
          props.onError(error, info);
        }
      }}
    />
  );
};
