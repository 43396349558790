import { cn } from 'src/shadcn/lib/utils';
import * as React from 'react';
import { TIconName } from 'src/Components/Shared/Icon';
import { Text } from 'src/Components/Shared/Text';

import { RowItemIcon } from './RowItem/RowItemIcon';
import './Toast.module.css';

export enum ToastType {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success',
  loading = 'loading',
}

export type TSetToast = (
  toast: Pick<IToastProps, 'text' | 'type'> | undefined,
  duration?: number
) => void;

export interface IToastProps {
  type: ToastType;
  text: string;
  onDismiss: () => void;
  className?: string;
}

function getIconColor(type: ToastType): string {
  switch (type) {
    case ToastType.error:
    case ToastType.info:
    case ToastType.success:
    case ToastType.warning:
    case ToastType.loading:
      return 'white';
  }
}

function getXoutColor(type: ToastType): string {
  switch (type) {
    case ToastType.error:
      return '#ea4f2b';
    case ToastType.success:
      return '#3ebc60';
    case ToastType.warning:
      return '#ed9501';
    case ToastType.loading:
    case ToastType.info:
      return 'rgb(156 163 175)';
  }
}

function getIconName(type: ToastType): TIconName {
  switch (type) {
    case ToastType.error:
    case ToastType.warning:
      return 'HiExclamation';
    case ToastType.info:
      return 'FiInfo';
    case ToastType.success:
      return 'BiCheckCircle';
    case ToastType.loading:
      return 'FiRotateCw';
  }
}

export const Toast: React.FunctionComponent<IToastProps> = props => {
  return (
    <div
      className={cn(
        props.className,
        'shadow-base flex flex-row items-center rounded border pb-2 pl-3 pr-3 pt-2 text-black',
        {
          ['border-red-600 bg-red-100']: props.type === ToastType.error,
          ['border-gray-400 bg-gray-100']:
            props.type === ToastType.info || props.type === ToastType.loading,
          ['border-green-600 bg-green-100']: props.type === ToastType.success,
          ['border-orange-600 bg-orange-100']: props.type === ToastType.warning,
        }
      )}
    >
      <RowItemIcon
        type={'icon'}
        containerClassName={cn('w-10 h-10', {
          ['bg-red-600']: props.type === ToastType.error,
          ['bg-gray-400']:
            props.type === ToastType.info || props.type === ToastType.loading,
          ['bg-green-600']: props.type === ToastType.success,
          ['bg-orange-600']: props.type === ToastType.warning,
        })}
        iconClassName={cn({
          'loading-icon': props.type === ToastType.loading,
        })}
        iconProps={{
          name: getIconName(props.type),
          color: getIconColor(props.type),
          size: 20,
        }}
      />
      <Text text={props.text} variant={'large'} className={'ml-3'} />
      <RowItemIcon
        type={'icon'}
        containerClassName={cn(
          'w-10 h-10 p3 transparent hover:text-white ml-auto'
        )}
        iconProps={{
          name: 'HiX',
          color: getXoutColor(props.type),
          size: 20,
        }}
        onClick={() => {
          props.onDismiss();
        }}
      />
    </div>
  );
};
