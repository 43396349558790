export const Colors = {
  white: 'white',

  dashboardColor1: '#c94c4c',
  dashboardColor2: '#034f84',
  dashboardColor3: '#50394c',
  dashboardColor4: '#618685',
  dashboardColor5: '#eea29a',
  dashboardColor6: '#b5e7a0',
  dashboardColor7: '#b9936c',
  dashboardColor8: '#92a8d1',

  primaryBlue: '#006ce5',

  secondaryText: '#797979',
};
