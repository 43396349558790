export enum EnvType {
  DEV = 'development',
  PREVIEW = 'preview',
  PROD = 'production',
}

export interface ISquareEnv {
  applicationId: string;
  inputComponentScriptEndpoint: string;
}

interface IStripeSubscription {
  id: string;
  pricePerMonth: number;
}

export interface IEnvironment {
  productionEndpoint: string;
  endpoint: {
    current: string;
    socket: string;
  };
  envType: EnvType;
  square: ISquareEnv;
  stripe: {
    subscriptions: {
      monthly: IStripeSubscription;
      annually: IStripeSubscription;
    };
    priceTable: {
      id: string;
      publishableKey: string;
    };
    api_key: string;
  };
  segment: {
    apiKey: string;
  };
  drift: {
    appId: string;
  };
  cloudflare: {
    siteKey: string;
  };
}
