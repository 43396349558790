import { injectable } from 'inversify';

import { StringResources } from '../../assets/StringResources';
import {
  IStringValidationService,
  IValidationResult,
  InputStringType,
} from './IStringValidationService';

interface IValidationPair {
  validate: (value?: string) => boolean;
  errorMessage: string;
}

const regexDefs = {
  emails: /.*/,
  phone: /^\d{10}$/,
};

@injectable()
export class StringValidationService implements IStringValidationService {
  private validationPairsMap: Map<InputStringType, IValidationPair> = new Map();
  public constructor() {
    const pairs = [
      {
        type: InputStringType.USERNAME,
        errorMessage: StringResources.usernameValidationError,
        validate: (value?: string) => !!value && value.length > 7,
      },
      {
        type: InputStringType.PASSWORD,
        errorMessage: StringResources.passwordValidationError,
        validate: (value?: string) => !!value && value.length > 7,
      },
      {
        type: InputStringType.EMAIL,
        errorMessage: StringResources.emailVE,
        validate: (value?: string) => !!value && regexDefs.emails.test(value),
      },
      {
        type: InputStringType.NAME,
        errorMessage: StringResources.fullNameVE,
        validate: (value?: string) =>
          !!value && value.length > 4 && value.indexOf(' ') > -1,
      },
      {
        type: InputStringType.FIRST_NAME,
        errorMessage: 'First name should not be empty',
        validate: (value?: string) => !!value && value.length > 0,
      },

      {
        type: InputStringType.LAST_NAME,
        errorMessage: 'Last name should not be empty',
        validate: (value?: string) => !!value && value.length > 0,
      },
      {
        type: InputStringType.PHONE,
        errorMessage: StringResources.phoneNumberVE,
        validate: (value?: string) => !!value && regexDefs.phone.test(value),
      },
      {
        type: InputStringType.NON_EMPTY,
        errorMessage: StringResources.nonEmptyVE,
        validate: (value?: string) => !!value,
      },
    ];
    pairs.forEach(p =>
      this.validationPairsMap.set(p.type, {
        ...p,
      })
    );
  }

  public validate(
    type: InputStringType,
    value: string | undefined
  ): IValidationResult {
    const pair = this.validationPairsMap.get(type);
    const errorMessage = !pair?.validate(value)
      ? pair?.errorMessage
      : undefined;
    return { errorMessage };
  }
}
