import { injectable } from 'inversify';
import { DI_TYPES } from 'src/dependencyInjection/diTypes';
import {
  ILocalStorageService,
  LocalStorageItem,
} from '../LocalStorageService/ILocalStorageService';
import { IAuthService, IAuthUser } from './IAuthService';
import { diContainer } from 'src/dependencyInjection/diContainer';
import { IApolloService } from '../ApolloService/IApolloService';
import { IBrowserHistory } from '../BrowserHistory/IBrowserHistoryService';
import { RouterLinks } from 'src/routing/routerLinks';
import { IAnalyticsService } from '../AnalyticsService/IAnalyticsService';
import { z } from 'zod';
import {
  AccountType,
  AuthTokenType,
} from 'src/graphql/__generated__/globalTypes';

@injectable()
export class AuthService implements IAuthService {
  public logout(): void {
    diContainer
      .get<ILocalStorageService>(DI_TYPES.LocalStorageService)
      .remove(LocalStorageItem.USER);
    diContainer
      .get<IApolloService>(DI_TYPES.ApolloService)
      .getClient()
      .resetStore();
    diContainer
      .get<IBrowserHistory>(DI_TYPES.BrowserHistoryService)
      .push(RouterLinks.Login);
    diContainer.get<IAnalyticsService>(DI_TYPES.AnalyticsService).resetUser();
  }

  public getCurrentUser(): IAuthUser | undefined {
    const localStorageService = diContainer.get<ILocalStorageService>(
      DI_TYPES.LocalStorageService
    );

    const userString = localStorageService.get(LocalStorageItem.USER);
    if (!userString) {
      return undefined;
    }
    try {
      return z
        .object({
          token: z.string(),
          authTokenType: z.nativeEnum(AuthTokenType),
          user: z.object({
            authUserType: z.nativeEnum(AccountType),
            id: z.string(),
            companyName: z.string().nullish(),
            email: z.string().email().nullish(),
          }),
          shopLocation: z
            .object({
              id: z.string(),
              name: z.string(),
            })
            .nullish(),
          employee: z
            .object({
              id: z.string(),
              firstName: z.string(),
              lastName: z.string(),
            })
            .nullish(),
        })
        .parse(JSON.parse(userString));
    } catch (e) {
      return undefined;
    }
  }

  public setCurrentUser(user: IAuthUser): void {
    diContainer
      .get<ILocalStorageService>(DI_TYPES.LocalStorageService)
      .set(LocalStorageItem.USER, JSON.stringify(user));
  }
}
