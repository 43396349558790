import { z } from 'zod';
import {
  FeatureFlag,
  FeatureFlagValidator,
  IFeatureFlagService,
} from './FeatureFlagServiceTypes';
import { injectable } from 'inversify';

@injectable()
export class FeatureFlagService implements IFeatureFlagService {
  public constructor() {
    this.getCurrentFeatureFlags = this.getCurrentFeatureFlags.bind(this);
  }

  private featureFlagKey = 'featureFlags';

  public getCurrentFeatureFlags() {
    try {
      return (
        FeatureFlagValidator.parse(
          JSON.parse(localStorage.getItem(this.featureFlagKey) || '{}')
        ) || {}
      );
    } catch (e) {
      return {};
    }
  }

  public setFeatureFlag(feature: FeatureFlag, newValue: boolean) {
    const currentFeatureFlags = this.getCurrentFeatureFlags();
    const newFeatureFlags: Partial<Record<FeatureFlag, boolean | undefined>> = {
      ...currentFeatureFlags,
    };
    newFeatureFlags[feature] = newValue;
    localStorage.setItem(this.featureFlagKey, JSON.stringify(newFeatureFlags));
    return newFeatureFlags;
  }
}
