import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { inject, injectable } from 'inversify';
import { DI_TYPES } from 'src/dependencyInjection/diTypes';
import { uniques } from 'src/utils/utils';
import { IAuthService } from '../AuthService/IAuthService';
import { IEnvironmentService } from '../EnvironmentService/IEnvironmentService';
import { IApolloService } from './IApolloService';
import { SegmentApolloLink } from './ApolloLinks.ts/SegmentApolloLink';
import { diContainer } from 'src/dependencyInjection/diContainer';
import { GraphQLUserErrors } from 'src/graphql/__generated__/globalTypes';
import { z } from 'zod';

@injectable()
export class ApolloService implements IApolloService {
  @inject(DI_TYPES.EnvironmentService)
  private environmentService: IEnvironmentService;

  @inject(DI_TYPES.AuthService)
  private authService: IAuthService;

  private client: ApolloClient<unknown>;

  private onError?: (code: GraphQLUserErrors) => void;

  public getClient(): ApolloClient<unknown> {
    if (!this.client) {
      this.client = this.setupClient();
    }
    return this.client;
  }

  public setOnError(onError: (code: GraphQLUserErrors) => void) {
    this.onError = onError;
  }

  private setupClient(options?: { ssrMode?: boolean }): ApolloClient<unknown> {
    const cache = this.setupApolloCache();
    return new ApolloClient({
      // ssrMode: options?.ssrMode,
      link: this.setupApolloLinks(),
      cache,
      assumeImmutableResults: true,
    });
  }

  private getWsLink() {
    if (typeof window === 'undefined') {
      return undefined;
    }
    const wsURI = `${
      this.environmentService.get().endpoint.socket
    }subscriptions`;
    return new GraphQLWsLink(
      createClient({
        url: wsURI,
        connectionParams: () => {
          return {
            authToken: this.getToken(),
          };
        },
      })
    );
  }

  private getToken() {
    const user = this.authService.getCurrentUser();
    return user?.token ? `Bearer ${user.token}` : undefined;
  }

  private handleErrorCode(code: GraphQLUserErrors | undefined) {
    switch (code) {
      case GraphQLUserErrors.UNAUTHENTICATED:
        diContainer.get<IAuthService>(DI_TYPES.AuthService).logout();
        break;
      case GraphQLUserErrors.INVALID_SUBSCRIPTION:
        if (this.onError) {
          this.onError(code);
        }
    }
  }

  private setupApolloLinks(): ApolloLink {
    const httpLink = createUploadLink({
      uri: `${this.environmentService.get().endpoint.current}graphql`,
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          for (const {
            message,
            locations,
            path,
            extensions,
          } of graphQLErrors) {
            const validation = z
              .nativeEnum(GraphQLUserErrors)
              .optional()
              .safeParse(extensions?.code);
            if (validation.success) {
              this.handleErrorCode(validation.data);
            }
          }
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }
    );

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: this.getToken(),
        },
      };
    });
    const wsLink = this.getWsLink();
    const link = wsLink
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          from([errorLink, wsLink]),
          from([errorLink, authLink, SegmentApolloLink, httpLink])
        )
      : from([errorLink, authLink, SegmentApolloLink, httpLink]);

    return link;
  }

  private setupApolloCache(): ApolloCache<unknown> {
    return new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            listPayments: {
              keyArgs: false,
              merge: mergeListItems,
            },
            listInvoiceItemDefinitions: {
              keyArgs: false,
              merge: mergeListItems,
            },
            getCustomers: {
              keyArgs: false,
              merge: mergeListItems,
            },
            listCars: {
              keyArgs: ['customerId'],
              merge: mergeListItems,
            },
            listInventoryItems: {
              keyArgs: false,
              merge: mergeListItems,
            },
            listExpenses: {
              keyArgs: false,
              merge: mergeListItems,
            },
            // listShifts: {
            //   keyArgs: false,
            //   merge(
            //     existing: {
            //       items: unknown[];
            //       count: number;
            //     } = {
            //       items: [],
            //       count: 0,
            //     },
            //     incoming,
            //     c
            //   ) {
            //     const offset =
            //       z
            //         .object({
            //           args: z.object({ offset: z.number().nullish() }),
            //         })
            //         .parse(c).args.offset || 0;
            //     const merged = existing.items.length
            //       ? existing.items.slice(0)
            //       : Array(incoming.items.count).fill(null);
            //     for (let i = 0; i < incoming.items.length; ++i) {
            //       const item = incoming.items[i];
            //       if (item) {
            //         merged[i + offset] = item;
            //       }
            //     }
            //     const res = {
            //       count: incoming.count || existing.count || 0,
            //       items: merged,
            //     };
            //     return res;
            //   },
            // },
            getTodaysJobs: {
              keyArgs: false,
              merge(
                existing: {
                  items: unknown[];
                  count: number;
                  offset: number | null;
                } = {
                  items: [],
                  count: 0,
                  offset: null,
                },
                incoming,
                c
              ) {
                const offset =
                  z
                    .object({
                      args: z.object({ offset: z.number().nullish() }),
                    })
                    .parse(c).args.offset || 0;
                const merged = existing.items.length
                  ? existing.items.slice(0)
                  : Array(incoming.items.count).fill(null);
                for (let i = 0; i < incoming.items.length; ++i) {
                  const item = incoming.items[i];
                  if (item) {
                    merged[i + offset] = item;
                  }
                }
                const res = {
                  offset: incoming.offset || existing.offset || 0,
                  count: incoming.count || existing.count || 0,
                  items: merged,
                };
                return res;
              },
            },
            listJobsForTable: {
              keyArgs: a => {
                return ['options'];
              },
              merge(
                existing: {
                  items: unknown[];
                  count: number;
                  offset: number | null;
                } = {
                  items: [],
                  count: 0,
                  offset: null,
                },
                incoming,
                c
              ) {
                const offsetValidation = z
                  .object({
                    args: z.object({
                      input: z.object({ offset: z.number().nullish() }),
                    }),
                  })
                  .safeParse(c);
                const offset = offsetValidation.success
                  ? offsetValidation.data.args.input.offset || 0
                  : 0;
                const merged = existing.items.length
                  ? existing.items.slice(0)
                  : Array(incoming.count).fill(null);
                for (let i = 0; i < incoming.items.length; ++i) {
                  const item = incoming.items[i];
                  if (item) {
                    merged[i + offset] = item;
                  }
                }
                const res = {
                  offset: incoming.offset || existing.offset || 0,
                  count: incoming.count || existing.count || 0,
                  items: merged,
                };
                return res;
              },
            },
            listJobs: {
              keyArgs: a => {
                return ['options'];
              },
              merge(
                existing: {
                  items: unknown[];
                  count: number;
                  offset: number | null;
                } = {
                  items: [],
                  count: 0,
                  offset: null,
                },
                incoming,
                c
              ) {
                const offsetValidation = z
                  .object({
                    args: z.object({
                      input: z.object({ offset: z.number().nullish() }),
                    }),
                  })
                  .safeParse(c);
                const offset = offsetValidation.success
                  ? offsetValidation.data.args.input.offset || 0
                  : 0;
                const merged = existing.items.length
                  ? existing.items.slice(0)
                  : Array(incoming.items.count).fill(null);
                for (let i = 0; i < incoming.items.length; ++i) {
                  const item = incoming.items[i];
                  if (item) {
                    merged[i + offset] = item;
                  }
                }
                const res = {
                  offset: incoming.offset || existing.offset || 0,
                  count: incoming.count || existing.count || 0,
                  items: merged,
                };
                return res;
              },
            },
            listChatsForCustomer: {
              keyArgs: ['customerId'],
              merge(existing = { chats: [] }, incoming, c) {
                const res = {
                  ...incoming,
                  chats: uniques(
                    [
                      ...existing.chats,
                      ...incoming.chats.map((item: any) => {
                        return { ...item, id: c.readField('id', item) };
                      }),
                    ],
                    item => item.id
                  ).sort((r1, r2) => {
                    return (
                      new Date(
                        c.readField('createdAt', r2) || new Date()
                      ).getTime() -
                      new Date(
                        c.readField('createdAt', r1) || new Date()
                      ).getTime()
                    );
                  }),
                };
                return res;
              },
            },
            listUpcomingAppointments: {
              keyArgs: false,
              merge: mergeAppointments,
            },
            listPastAppointments: {
              keyArgs: false,
              merge: mergeAppointments,
            },
          },
        },
      },
    });
  }
}

function mergeListItems(existing = { items: [] }, incoming: any, c: any) {
  const res = {
    ...incoming,
    items: uniques(
      [
        ...existing.items,
        ...incoming.items.map((item: any) => {
          return { ...item, id: c.readField('id', item) };
        }),
      ],
      item => item.id
    ),
  };
  return res;
}

function mergeAppointments(existing = { items: [] }, incoming: any, c: any) {
  const merged = mergeListItems(existing, incoming, c);
  (merged.items as Array<{ expectedDropoffTime: number }>).sort((a, b) => {
    const aExpectedDropOffTime = new Date(a.expectedDropoffTime);
    const bExpectedDropOffTime = new Date(b.expectedDropoffTime);
    if (aExpectedDropOffTime < bExpectedDropOffTime) {
      return -1;
    }
    if (aExpectedDropOffTime > bExpectedDropOffTime) {
      return 1;
    }
    return 0;
  });
  return merged;
}
