import * as React from 'react';
import '../../styles/globals.css';
import type { AppProps } from 'next/app';
import { diContainer } from 'src/dependencyInjection/diContainer';
import Head from 'next/head';
import { bindServices } from 'src/dependencyInjection/diConfig';
import { DI_TYPES } from 'src/dependencyInjection/diTypes';
import RModal from 'react-modal';
import { NextPage } from 'next';
import { IAnalyticsService } from 'src/services/AnalyticsService/IAnalyticsService';
import Script from 'next/script';
import { ErrorPageContainer } from 'src/Components/ErrorPage/ErrorPageContainer';
import { ErrorPageLogo } from 'src/Components/ErrorPage/ErrorPageLogo';
import { ErrorBoundary } from 'src/Components/ErrorPage/ErrorBoundary';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

bindServices(diContainer);
// This initializes analytics.
diContainer.get<IAnalyticsService>(DI_TYPES.AnalyticsService);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const title = 'Sparkify';
  const currentUrl = 'https://www.shopsparkify.com'; // Move to env
  const getLayout = Component.getLayout || (page => page);
  return (
    <>
      <Script type='text/javascript' src='/static/segment.js' />
      <Script async src='https://js.stripe.com/v3/pricing-table.js' />
      <Script
        async
        defer
        src='https://challenges.cloudflare.com/turnstile/v0/api.js'
      />

      <Head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
        <meta name='theme-color' content='#000000' />

        <title>{title}</title>
        <meta
          name='description'
          content='Supercharge your business. Delight your customers.'
        />

        <meta property='og:url' content={currentUrl} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta
          property='og:description'
          content='Supercharge your business. Delight your customers.'
        />
        <meta property='og:image' content='' />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content={currentUrl} />
        <meta property='twitter:url' content={currentUrl} />
        <meta name='twitter:title' content={title} />
        <meta
          name='twitter:description'
          content='Supercharge your business. Delight your customers.'
        />
        <meta name='twitter:image' content='' />
      </Head>
      <ErrorBoundary
        fallback={
          <div className='flex flex-1 flex-grow flex-col'>
            <ErrorPageContainer>
              <ErrorPageLogo />
              <h1>
                <span className='text-lg font-bold'>Oops!</span> An error
                occured.
              </h1>
            </ErrorPageContainer>
          </div>
        }
      >
        {getLayout(<Component {...pageProps} />)}
      </ErrorBoundary>
    </>
  );
}
RModal.setAppElement('body');
export default MyApp;
