import Axios from 'axios';
import { injectable } from 'inversify';

import {
  HttpGetConfig,
  HttpPostConfig,
  HttpPutConfig,
  IHttpReturnType,
  IHttpService,
  IHttpServiceConfig,
} from './IHttpService';
import { z } from 'zod';

@injectable()
export class HttpService implements IHttpService {
  public async send<TOutput extends z.ZodType, TInput>(
    config: IHttpServiceConfig<TOutput, TInput>
  ): Promise<IHttpReturnType<ReturnType<TOutput['parse']>>> {
    switch (config.type) {
      case 'POST':
        return this.post(config);
      case 'GET':
        return this.get(config);
      case 'PUT':
        return this.put(config);
    }
  }

  private async post<TOutput extends z.ZodType, TInput>(
    config: HttpPostConfig<TOutput, TInput>
  ): Promise<IHttpReturnType<ReturnType<TOutput['parse']>>> {
    const response = await Axios.post(config.url, config.data, {
      headers: config.headers,
    });
    return config.response.parse(response.data);
  }

  private async get<TOutput extends z.ZodType>(
    config: HttpGetConfig<TOutput>
  ): Promise<IHttpReturnType<ReturnType<TOutput['parse']>>> {
    const response = await Axios.get(config.url, { headers: config.headers });
    return config.response.parse(response.data);
  }

  private async put<TOutput extends z.ZodType, TInput>(
    config: HttpPutConfig<TOutput, TInput>
  ): Promise<IHttpReturnType<ReturnType<TOutput['parse']>>> {
    const response = await Axios.put(config.url, config.data, {
      headers: config.headers,
    });
    return config.response.parse(response.data);
  }
}
