import { injectable } from 'inversify';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { lazyInject } from 'src/dependencyInjection/diDecorators';
import { DI_TYPES } from 'src/dependencyInjection/diTypes';
import { IEnvironmentService } from '../EnvironmentService/IEnvironmentService';
import { IStripeService } from './StripeServiceTypes';

@injectable()
export class StripeService implements IStripeService {
  @lazyInject(DI_TYPES.EnvironmentService)
  private environmentService: IEnvironmentService;

  private stripe: Promise<Stripe | null>;

  public async getStripe() {
    if (!this.stripe) {
      this.stripe = loadStripe(this.environmentService.get().stripe.api_key);
    }
    return this.stripe;
  }
}
