import * as React from 'react';
import { cn } from 'src/shadcn/lib/utils';

export interface ITextProps {
  text: string;
  variant?: string;
  onClick?: () => void;
  className?: string;
}

export const Text: React.FunctionComponent<ITextProps> = props => {
  return (
    <span
      className={cn(props.className, {
        ['cursor-pointer hover:underline']: props.onClick,
      })}
      onClick={
        props.onClick &&
        (e => {
          e.stopPropagation();
          e.preventDefault();
          if (props.onClick) {
            props.onClick();
          }
        })
      }
    >
      {props.text}
    </span>
  );
};
