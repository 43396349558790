export const StringResources = {
  Ok: 'Ok',
  Quote: 'Quote',
  Invoice: 'Invoice',
  messageQuote: 'SMS Quote',
  searchWorkOrderByNumber: 'Search Work Order By Number',
  none: 'None',
  savingInvoice: 'Saving invoice',
  workOrderHasBeenSaved: 'Work order has been saved',
  savingJob: 'Saving work order',
  deletingJob: 'Deleting work order',
  ViewCustomer: 'View Customer',
  view: 'View',
  Image: 'Image',
  editExpense: 'Edit Expense',
  workOrder: 'Work Order',
  expenseType: 'Expense Type',
  selectIExpenseType: 'Select expense type',
  confirmPayment: 'Confirm Payment',
  confirmInteracPayment: 'Confirm Interac Money Transfer Payment',
  confirmDeleteJobMessage: 'Please confirm deleting this job.',
  NoCarSelected: 'No Car Selected',
  Select: 'Select',
  Unpaid: 'Not Paid',
  inProgress: 'In Progress',
  complete: 'Complete',
  Oil: 'Oil',
  UnderCoat: 'Under Coat',
  Other: 'Other',
  Unknown: 'Unknown',
  Tire: 'Tire',
  Purchase: 'Purchase',
  Edit: 'Edit',
  Details: 'Details',
  send: 'send',
  chat: 'chat',
  maxNumebrDeliveriesPerCustomer: 'Maximum number of deliveries per customer',
  isCampaignActive: 'Activate Campaign',
  triggerSite: 'Trigger site',
  numberOfDeliveries: 'Number of deliveries:',
  metrics: 'Metrics',
  newCampaign: 'New Campaign',
  run: 'Run',
  conversionEvent: 'Conversion Event',
  manual: 'Manual',
  triggered: 'Triggered',
  targeting: 'Targeting',
  campaignUrl: 'Campaign URL',
  campaignMessage: 'Campaign Message',
  campaignName: 'Campaign Name',
  sms: 'SMS',
  campaigns: 'Campaigns',
  campaign: 'Campaign',
  editJob: 'Edit Job',
  downloadReport: 'Download .csv report',
  totalSpend: 'Total spend',
  customerSince: 'Customer since',
  addDescription: 'Please add a description',
  selectCategory: 'Please select a category',
  inputPaidTo: 'Please input paid to',
  selectDate: 'Please select a date',
  amountShouldBeGreaterThanZero: 'Amount should be greater than $0',
  syncSquarePayments: 'Sync Square Payments',
  never: 'never',
  numberOfRepeatCustomers: '✅ Repeat customers.',
  clear: 'Clear',
  numberOfHours: 'Number of shift hours',
  numberOfShifts: 'Number of Shifts',
  average: 'Average',
  numberOfNewCustomers: '✨ New customers',
  numberOfCustomers: '➕ Total customers',
  from: 'From',
  to: 'To',
  averagePayment: 'Average payment',
  total: 'Total',
  amount: 'Amount',
  count: 'Count',
  expensesFileHasBeenUploadedSuccessfully:
    'File has been uploaded successfully.',
  upload: 'Upload',
  uploadExpenseImage: 'Upload Expense Image',
  download: 'Download',
  square: 'Square',
  dashboard: 'Dashboard',
  forgotPassword: 'Forgot passsword',
  endTime: 'End Time',
  startTime: 'Start Time',
  pay: 'Pay',
  price: 'Price',
  inventory: 'Inventory',
  amountPaid: 'Amount paid',
  taxesPaid: 'Taxes paid',
  paidTo: 'Paid to',
  paidAt: 'Paid at',
  category: 'Category',
  categories: 'Categories',
  description: 'Description',
  done: 'Done',
  Expense: 'Expense',
  expenses: 'Expenses',
  customers: 'Customers',
  customer: 'Customer',
  add: 'Add',
  addCar: 'Add car',
  frontDesk: 'Front Desk',
  mechanic: 'Mechanic',
  addNewCustomer: 'Add',
  name: 'Name',
  email: 'Email',
  save: 'Save',
  make: 'Make',
  model: 'Model',
  year: 'Year',
  colour: 'Colour',
  cars: 'Cars',
  workOrders: 'Work Orders',
  deleteCustomer: 'Delete Customer',
  timeCreated: 'Time Created',
  jobType: 'Job Type',
  workOrderComments: 'Work Order Comments',
  mechanicComments: 'Mechanic Comments',
  finalTotal: 'Final Total',
  paymentType: 'Payment Type',
  Car: 'Car',
  Service: 'Service',
  saveJob: 'Save Job',
  jobStatus: 'Job Status',
  car: 'Car',
  username: 'Username',
  password: 'Password',
  confirmPassword: 'Confirm password',

  passwordValidationError: 'Password must be 8 characters long',
  usernameValidationError: 'Username must be 8 characters long',
  psswordShouldMatch: 'Passwords should match',
  signupFailed: 'Failed to create account. Please try again.',
  loginFailed: 'Failed to log in. Please try again.',
  NewCustomer: 'New Customer',
  search: 'Search names',
  emailVE: 'Invalid email',
  fullNameVE: 'Please input first and last name',
  phoneNumberVE: 'Please input a valid phone number',
  nonEmptyVE: 'Value cannot be empty',
  close: 'Close',
  addingCustomer: 'Adding job...',
  savingCustomer: 'Saving customer...',
  deletingCustomer: 'Deleting customer...',

  editCar: 'Edit Car',
  saveCar: 'Save Car',
  cancel: 'Cancel',
  addingCar: 'Adding car',
  disconnectSquare: 'Disconnect Square',

  myAccount: 'My Account',
  company: 'Company',

  newCustomer: 'New Customer',
  existingCustomer: 'Existing Customer',
  newJob: 'New Job',
  back: 'Back',
  next: 'Next',
  exit: 'Exit',
  errorRetreivingCustomers: 'Error retrieving customers please go back.',
  errorRetreivingCars: 'Error retrieving cars. Please go back.',
  payment: 'Payment',
  delete: 'delete',
  review: 'Review',
  submit: 'Submit',
  notSureIfConnectedToSquare:
    'Failed to check if connected to Square. Please refresh your page.',
  failedToDisconnectFromSquare: 'Failed to disconnect from Square.',
  failedToSaveInfo: 'Failed to save info. Please try again.',
  addNewCar: 'Add new car',
  addItem: 'Add item',
  squareDeviceId: 'Square device id',
  connectSquareDeviceMessage:
    'Use this code to sign into your Square Terminal device',
  deviceIsPaired: 'Your terminal device  is paired.',
  connectingToSquare: 'Connecting to Square...',
  cash: 'Cash',
  card: 'Card',
  savings: 'Savings',
  tax: 'Tax',
  subtotal: 'Subtotal',
  internalError: 'Internal Error!',
  addDiscount: 'Add Discount',
  discount: 'Discount',
  customItem: 'Custom Item',
  paymentIsPending: 'Payment is pending',
  paymentIsCancelled: 'Payment is cancelled',
  paymentCancellationRequested: 'Payment cancellation requested',
  errorSendingPayment: 'Error Sending payment, please try again.',
  employees: 'Employees',
  startNewShift: 'Start New Shift',
  newEmployee: 'New Employee',
  saveEmployee: 'Save Employee',
  failedToSaveEmployee: 'Failed to save employee.',
  firstName: 'First Name',
  lastName: 'Last Name',
  nameVE: 'Name must not be empty',
  socialSecurity: 'Social Insurance Number',
  shifts: 'Shifts',
  totalCash: 'Total cash',
  totalCard: 'Total card',
  totalDue: 'Total due',
  deleteJob: 'Delete Work Order',
  failedToDeleteJob: 'Failed to delete work order',
  failedToLoadEmployeeAfterSaving:
    'Failed to load employee after saving, please refresh.',
  savedEmployee: 'Employee saved successfully',
  addEmployee: 'Add employee',
  payments: 'Payments',
  invoice: 'Invoice',
  notConnectedtoSquare: 'You are not connected to square!',
  locations: 'Locations',
  selectALocation: 'Select a location',
  currency: 'Currency',
  selectACurrency: 'Select a currency',
  deviceNotPaired: 'Square Terminal is not paired',
  searchByPhoneNumber: 'Search by phone number',
  noMoreToLoad: 'No more to load :)',
  saveCustomer: 'Save customer',
  editCustomer: 'Edit customer',
  failedToSaveCustomer: 'Failed to save customer',
  editEmployee: 'Edit employee',
  loadMore: 'Load more',
  terminalPairingStatus: 'Terminal pairing status',
  paired: 'Paired',
  notPaired: 'Not paired',
  mustSelectCurrency: 'Must select a currency',
  mustSelectLocation: 'Must select a location',
  mustSelectTaxPercentage: 'Must set tax percentage',
  someChangesHaveNotBeenSaved: 'Some changes have not been saved yet',
  priceVE: 'Price must not be 0',
  quantityVE: 'Item must have at least 1',
  mustHaveOneItemInInvoice: 'Must have one item in invoice.',
  invoiceMustBeAtLeastOneDollar: 'Invoice total must be at least $1',
  SelectNoCar: 'No car',
  jobDate: 'Job Date',
};
