import { injectable } from 'inversify';
import Cookies, { CookieSetOptions } from 'universal-cookie';

import {
  CookieType,
  ILocalStorageService,
  LocalStorageItem,
} from './ILocalStorageService';

@injectable()
export class LocalStorageService implements ILocalStorageService {
  private cookies;
  public constructor() {
    this.cookies = new Cookies();
  }

  public set(item: LocalStorageItem, data: string): void {
    localStorage.setItem(item, data);
  }

  public get(item: LocalStorageItem): string | undefined {
    const itemString = localStorage.getItem(item);
    if (!itemString) {
      return undefined;
    }
    return itemString;
  }

  public remove(item: LocalStorageItem): void {
    localStorage.removeItem(item);
  }

  public saveCookie(
    cookieType: CookieType,
    item: string,
    options: Omit<CookieSetOptions, 'sameSite'> = {}
  ): void {
    this.cookies.set(cookieType, item, { sameSite: 'strict', ...options });
  }

  public getCookie(cookieType: CookieType): string | undefined {
    return this.cookies.get(cookieType);
  }

  public removeCookie(cookieType: CookieType): void {
    this.cookies.remove(cookieType);
  }
}
