import { injectable } from 'inversify';
import { IAnalyticsService } from './IAnalyticsService';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { AnalyticsEvent } from './AnalyticsServiceTypes';
import { IAuthUser } from '../AuthService/IAuthService';

@injectable()
export class AnalyticsService implements IAnalyticsService {
  private analytics?: AnalyticsBrowser;
  public constructor() {
    try {
      this.analytics = AnalyticsBrowser.load({
        writeKey: 'rCfJWayIcOeSGdCO4srSm92YSmV2mGD3',
      });
    } catch {
      console.log('failed to initialize analytics service.');
    }
  }
  public identifyUser(user: IAuthUser): void {
    this.analytics?.identify(user.user.id);
  }

  public resetUser(): void {
    this.analytics?.reset();
  }

  public track(
    event: AnalyticsEvent,
    extraData?: Record<string, string>
  ): void {
    this.analytics?.track(event, extraData);
  }

  public trackButtonClick(
    buttonId: string,
    extraData: Record<string, string>
  ): void {
    this.track(AnalyticsEvent.BUTTON_PRESS, { buttonId, ...(extraData || {}) });
  }

  public getAnalytics(): AnalyticsBrowser | undefined {
    return this.analytics;
  }

  public page(page: any): void {
    this.analytics?.page(page);
  }
}
