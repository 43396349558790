import { injectable } from 'inversify';
import { RouterLinks } from 'src/routing/routerLinks';
import {
  AccountFeatures,
  IAccountViewSettings,
  ISidebarService,
  ISquareSetupSettings,
  SidebarSetting,
  TSidebarItem,
  TSidebarRouterLinks,
} from './ISidebarService';

@injectable()
export class OwnerSidebarService implements ISidebarService {
  public getItems(): TSidebarItem[] {
    const res: TSidebarRouterLinks[] = [
      RouterLinks.Notifcations,
      RouterLinks.Appointments,
      RouterLinks.workOrders,
      RouterLinks.Payments,
      // RouterLinks.Customers,
      RouterLinks.expenses,
      RouterLinks.GPTDashboard,
    ];
    return res.map(link => ({ isDisabled: false, link }));
  }

  public getAvailableSettings(): SidebarSetting[][] {
    return [
      ['locations', 'service templates', 'employees', 'shifts'],
      ['billing', 'account', 'stripe-settings'],
      ['theme', 'logout'],
    ];
  }

  public getAccountViewSettings(): IAccountViewSettings {
    return {
      canSaveAccount: true,
      company: {
        isShown: true,
        isDisabled: false,
      },
      twilioPhoneNumber: {
        isShown: true,
        isDisabled: false,
      },
    };
  }

  public getSquareSetupSettings(): ISquareSetupSettings {
    return {
      canConnectToSquare: true,
      canConnectNewTerminal: true,
      canSyncPayments: true,
      canSetLocation: true,
      canSetCurrency: true,
      canSetTaxPercentage: true,
    };
  }

  public getEnabledFeatures(): AccountFeatures {
    return {
      createAndSearchResources: true,
      shiftInSidebar: false,
      manageShiftPage: false,
    };
  }
}
