import { createBrowserHistory } from 'history';
import { History } from 'history';
import { inject, injectable } from 'inversify';

import { RouterLinks } from '../../routing/routerLinks';
import { IBrowserHistory } from './IBrowserHistoryService';
import { IEnvironmentService } from '../EnvironmentService/IEnvironmentService';
import { DI_TYPES } from '../../dependencyInjection/diTypes';
import Router from 'next/router';

@injectable()
export class BrowserHistoryService implements IBrowserHistory {
  @inject(DI_TYPES.EnvironmentService)
  private environmentService: IEnvironmentService;

  public push(link: RouterLinks, extraRoutes: string[]): void {
    Router.push(this.getPath(link, extraRoutes));
  }

  public replace(link: RouterLinks, extraRoutes: string[]): void {
    Router.push(this.getPath(link, extraRoutes), undefined, {
      shallow: true,
    });
  }

  public get(): History {
    throw new Error('not implemented');
    // return this.browserHistory;
  }

  public getUri(link: RouterLinks, extraRoutes: string[] = []): string {
    return `${this.getPath(link, extraRoutes)}`;
  }

  private getPath(link: RouterLinks, extraRoutes: string[] = []): string {
    return '/' + link + (extraRoutes ? '/' + extraRoutes.join('/') : '');
  }

  public getFullPathUrl(link: RouterLinks, extraRoutes: string[] = []): string {
    return `${this.environmentService.get().productionEndpoint}${this.getPath(
      link,
      extraRoutes
    )}`;
  }
}
